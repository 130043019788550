export const environment = {
    APP_VERSION_MAJOR: 2,
    APP_VERSION_MINOR: 7,
    APP_VERSION_PATCH: 0,

    PRODUCTION: false,
    STAGING: false,

    NODE_BASE_URL: 'https://api-node.dev.engincloud.com',
    NODE_API_URL: 'https://api-node.dev.engincloud.com/api',
    PYTHON_API_URL: 'http://3.97.230.182:9092/engin/v2/python', // not deployed

    OAUTH_CLIENT_ID: 'GGqLE1jogCI2QHvGuOXuWhHSfwtZa6XyliBWFg5k',
    OAUTH_CLIENT_SECRET: 'mto1Xrbw0TzeONS4nVRn3iR7TYoMEGIsGaIG8CzR',

    SSO_ENABLED: true,
    AZURE_AD: {
        AUTHORITY: 'https://login.microsoftonline.com/organizations/',
        APPLICATION_ID: 'fad46356-bfa6-49ba-a699-5a911ba0c31f',
        REDIRECT_URL: 'https://dev.engincloud.com',
    },

    SENSITIVE_INFO_LOGIN: false,
    SENSITIVE_INFO_HEADER: false,
};
